import { ProcedureService } from './../services/procedure/procedure.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.scss']
})
export class MenuViewComponent implements OnInit {

  @Input() tab;

  packages;

  constructor(public router: Router, public procedureService:ProcedureService) {

    this.procedureService.getProcedureMetadata(0,100,'desc','PACKAGE').subscribe( data => {
      console.log(data);
      if(data!=null){
        this.packages = (data as any).procedureMetadata;
      }
    })

   }

  ngOnInit() {
    console.log(this.tab);
  }

  slides = [
    {img: "https://c.ndtvimg.com/2021-05/6erbd4r8_online-consultation-generic-650_625x300_13_May_21.jpeg",name:"COVID Consultation",showTopRight:true ,topRightContent:"COVID", topRightImg:"https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/images/Coronavirus.jpeg" , clickFunc:()=>{this.router.navigateByUrl('/consult/COVID Consultation');}},
    {img: "https://musculardystrophynews.com/forums/wp-content/uploads/2019/02/36574861_1881199352180576_3636196287197478912_n.jpg",name:"Dietician" , clickFunc:()=>{this.router.navigateByUrl('/consult/Dietician');}},
    {img: "https://www.careergirls.org/wp-content/uploads/2018/05/obgyn_1920x1080.jpg",name:"Gynaecology", clickFunc:()=>{this.router.navigateByUrl('/consult/Gynaecology');}},
    {img: "https://www.windsor.edu/wp-content/uploads/2018/10/considering-pediatrics-1109x675.jpg",name:"Pediatrics" , clickFunc:()=>{this.router.navigateByUrl('/consult/Pediatrics');}},
    {img: "https://media.istockphoto.com/photos/dermatologist-examining-patient-for-signs-of-skin-cancer-picture-id514880133?k=20&m=514880133&s=612x612&w=0&h=E8nOqpWfEy3Hl15eIS_w5lsBbmd3n8tMN55-tckVjxc=",name:"Dermatology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Dermatology');}},
    {img: "https://www.windsor.edu/wp-content/uploads/2018/08/career-in-psychiatry-1109x675.jpg",name:"Psychiatry" , clickFunc:()=>{this.router.navigateByUrl('/consult/Psychiatry');}},
    {img: "https://mehtahospital.com/wp-content/uploads/2016/01/ENT.jpg",name:"ENT" , clickFunc:()=>{this.router.navigateByUrl('/consult/ENT');}},
    {img: "https://assets-global.website-files.com/5e0143c66e14efc18c6f469d/5e20f0e5e400c637d5951e41_Orthopedics.jpg.png",name:"Orthopedics" , clickFunc:()=>{this.router.navigateByUrl('/consult/Orthopedics');}},
    {img: "https://sahyadrihospital.com/wp-content/uploads/2021/04/Pulmonology.jpg",name:"Pulmonology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Pulmonology');}},
    {img: "https://neyyarmedicity.com/wp-content/uploads/2020/09/General-Surgeon.jpg",name:"General Surgery" , clickFunc:()=>{this.router.navigateByUrl('/consult/General Surgery');}},
    {img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHTg-4gICAzjrWGKkTAzfBnPKcH9yN_qZpCA&usqp=CAU",name:"Oncology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Oncology');}},
    {img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlreNNP-0VIezGQU8QBaCDfBPUKoO33F4qPA&usqp=CAU",name:"Cardiology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Cardiology');}},
    {img: "https://res.cloudinary.com/highereducation/image/upload/c_fill,f_auto,fl_lossy,q_auto,w_1200,h_630/v1/resources/careers-psychology.jpg",name:"Psychology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Psychology');}},
    {img: "https://www.wth.org/wp-content/uploads/2019/03/neurology-hero.jpg",name:"Neurology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Neurology');}},
    {img: "https://images.medicinenet.com/images/article/main_image/urologist-1.jpg",name:"Urology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Urology');}},
    {img: "https://productimages.withfloats.com/actual/5ba331a53d05e3000109af97.jpg",name:"Diabetology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Diabetology');}},
    {img: "https://www.mukathospital.com/wp-content/uploads/2021/03/GastroEndo-Image.jpg",name:"Gastroenterology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Gastroenterology');}},
    {img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWDafLycaD2cdhjLze25LVdiEZxWhmAlfMNg&usqp=CAU",name:"Nephrology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Nephrology');}},
    {img: "https://cdn0.scrvt.com/39b415fb07de4d9656c7b516d8e2d907/1800000007393322/c65c3fb075bd/v/8fad22531fe1/neurosurgery_header_image_1800000007393322.jpg",name:"Neurosurgery" , clickFunc:()=>{this.router.navigateByUrl('/consult/Neurosurgery');}},
    {img: "https://marvel-b1-cdn.bc0a.com/f00000000038905/www.aao.org/full/image.axd?id=711b0d10-43ec-4f34-9733-36a58bfa7ad9&t=637522850761230000",name:"Ophthalmology" , clickFunc:()=>{this.router.navigateByUrl('/consult/Ophthalmology');}}
  ];
  slideConfig = {"slidesToShow": 5, "slidesToScroll": 1,"responsive": [{'breakpoint': 1200,'settings': {'slidesToShow': 4}},{'breakpoint': 1000,'settings': {'slidesToShow': 3}},{'breakpoint': 775,'settings': {'slidesToShow': 2,"autoplay": true,"slidesToScroll": 2}},{'breakpoint': 575,'settings': {'slidesToShow': 1,"autoplay": false}}]};

  slideConfigPackage = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [{'breakpoint': 1200,'settings': {'slidesToShow': 3}},{'breakpoint': 1000,'settings': {'slidesToShow': 2,"slidesToScroll": 2}},{'breakpoint': 775,'settings': {'slidesToShow': 1,"autoplay": true}}]};

  addSlide() {
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {

  }

  afterChange(e) {

  }

  beforeChange(e) {

  }

}
