import { ProfileComponent } from './profile/profile.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { TeleConsultationComponent } from './tele-consultation/tele-consultation.component';
import { SantobaComponent } from './santoba/santoba.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BusinessFlowComponent } from './business-flow/business-flow.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ConsultOnlineComponent } from './consult-online/consult-online.component';
import { MentalHealthSelfAssessmentComponent } from './mental-health-self-assessment/mental-health-self-assessment.component';
import { PaytmComponent } from './paytm/paytm.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProceduredetailsComponent } from './proceduredetails/proceduredetails.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SingupComponent } from './singup/singup.component';
import { AccountComponent } from './account/account.component';
import { RefreshComponent } from './refresh/refresh.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from './cart/cart.component';
import { LoginGuard } from './login.guard';
import { AllprocedureComponent } from './allprocedure/allprocedure.component';
import { GuestLoginComponent } from './guest-login/guest-login.component';
import { LiveVideoConsultationComponent } from './live-video-consultation/live-video-consultation.component';
import { ArticlesComponent } from './articles/articles.component';
import { EmcComponent } from './emc/emc.component';
import { PackageDetailsComponent } from './package-details/package-details.component';




const routes: Routes = [
  { path: 'procedure/:id',      component: ProceduredetailsComponent },
  { path: 'orders',      component: AccountComponent, canActivate: [ LoginGuard ] },
  { path: 'profile',      component: ProfileComponent, canActivate: [ LoginGuard ] },
  { path: 'login',      component: LoginComponent },
  { path: 'singup',      component: SingupComponent },
  { path: 'singup/:id',      component: SingupComponent },
  { path: 'page',      component: DynamicFormComponent },
  { path: 'page/:id',      component: DynamicFormComponent },
  { path: 'home',      component: HomeComponent },
  { path: 'about-us',      component: AboutUsComponent },
  { path: 'privacy-policy',      component: PrivacyPolicyComponent },
  { path: 't&c',      component: TermConditionsComponent },
  { path: 'refund-policy',      component: RefundPolicyComponent },
  { path: 'business-flow',      component: BusinessFlowComponent },
  { path: 'contact-us',      component: ContactUsComponent },
  // { path: 'live',      component: LiveVideoConsultationComponent },
  { path: 'refresh',      component: RefreshComponent },
  { path: 'cart',      component: CheckoutComponent },
  { path: 'self-assessment',      component: MentalHealthSelfAssessmentComponent },
  { path: 'tele-consult',      component: TeleConsultationComponent },
  { path: 'consult',      component: ConsultOnlineComponent },
  { path: 'consult/:pageId',      component: ConsultOnlineComponent },
  { path: 'category/:id',      component: AllprocedureComponent },
  { path: 'guest',      component: GuestLoginComponent },
  { path: 'guest/:id',      component: GuestLoginComponent },
  { path: 'pay/test',      component: PaytmComponent },
  { path: 'mental-health',      component: MentalHealthComponent },
  { path: 'org/:orgId',      component: EmcComponent },
  { path: 'org',      component: EmcComponent },
  { path: 'package/:packageId',      component: PackageDetailsComponent },
  { path: 'healthblogs/:articleId',      component: ArticlesComponent },
  { path: 'temp',      component: CartComponent, canActivate: [ LoginGuard ]},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true}),RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
