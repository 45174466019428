import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() banner;

  constructor(public router: Router) { }

  ngOnInit() {
    console.log(this.banner);
  }


  slides = [
    {name: "Emergency Services"},
    {name: "24/7 Doctor"},
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "autoplay": true};


  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }

}
