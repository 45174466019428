import { CouponDialog } from './dialog/coupon/coupon-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule, MatCheckbox, MatCheckboxModule, MatGridListModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';
import { StorageServiceModule} from 'angular-webstorage-service';
import { SlickCarouselModule } from 'ngx-slick-carousel';



// tslint:disable-next-line: max-line-length
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatButtonModule, MatCardModule, MatRadioModule } from '@angular/material';

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';


// PAYTM
import { CheckoutModule } from 'paytm-blink-checkout-angular';






import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { GettheappComponent } from './gettheapp/gettheapp.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { BannerSearchComponent } from './banner-search/banner-search.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { FooterComponent } from './footer/footer.component';
import { CategoryComponent } from './category/category.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { ProceduredetailsComponent } from './proceduredetails/proceduredetails.component';
import { HospitallistComponent } from './hospitallist/hospitallist.component';
import { LoginComponent } from './login/login.component';
import { SingupComponent } from './singup/singup.component';
import { AccountComponent } from './account/account.component';
import { RefreshComponent } from './refresh/refresh.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from './cart/cart.component';
import { AllprocedureComponent } from './allprocedure/allprocedure.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { GuestLoginComponent, FormatTimePipe } from './guest-login/guest-login.component';
import { DigitalCampComponent } from './digital-camp/digital-camp.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LiveVideoConsultationComponent } from './live-video-consultation/live-video-consultation.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PaytmComponent } from './paytm/paytm.component';
import { CheckoutDialog } from './dialog/checkout/checkout-dialog';
import { MenuViewComponent } from './menu-view/menu-view.component';
import { TeamComponent } from './team/team.component';
import { MentalHealthSelfAssessmentComponent } from './mental-health-self-assessment/mental-health-self-assessment.component';
import { ConsultOnlineComponent } from './consult-online/consult-online.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { BusinessFlowComponent } from './business-flow/business-flow.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SantobaComponent } from './santoba/santoba.component';
import { TeleConsultationComponent } from './tele-consultation/tele-consultation.component';
import { ArticlesComponent } from './articles/articles.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EmcComponent } from './emc/emc.component';
import { TempComponent } from './temp/temp.component';
import { BannerComponent } from './banner/banner.component';
import { PackageDetailsComponent } from './package-details/package-details.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { AddToCartButtonComponent } from './add-to-cart-button/add-to-cart-button.component';
import { ProfileComponent } from './profile/profile.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ToolbarComponent,
    GettheappComponent,
    InputPhoneComponent,
    BannerSearchComponent,
    QuickSearchComponent,
    CompanyDetailsComponent,
    FooterComponent,
    CategoryComponent,
    ProcedureComponent,
    ProceduredetailsComponent,
    HospitallistComponent,
    LoginComponent,
    SingupComponent,
    AccountComponent,
    RefreshComponent,
    CheckoutComponent,
    CartComponent,
    AllprocedureComponent,
    CompanyDetailsComponent,
    GuestLoginComponent,
    DigitalCampComponent,
    DynamicFormComponent,
    LiveVideoConsultationComponent,
    TestimonialsComponent,
    PaytmComponent,
    CheckoutDialog,
    CouponDialog,
    MenuViewComponent,
    TeamComponent,
    MentalHealthSelfAssessmentComponent,
    ConsultOnlineComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermConditionsComponent,
    RefundPolicyComponent,
    BusinessFlowComponent,
    ContactUsComponent,
    SantobaComponent,
    TeleConsultationComponent,
    ArticlesComponent,
    SafeHtmlPipe,
    EmcComponent,
    TempComponent,
    BannerComponent,
    PackageDetailsComponent,
    MentalHealthComponent,
    AddToCartButtonComponent,
    FormatTimePipe,
    ProfileComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSelectModule,
    StorageServiceModule,
    SlickCarouselModule,
    MatRadioModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'easymycare-f28b6'),
    AngularFireDatabaseModule,
    CheckoutModule,
    MatCheckboxModule,
    MatIconModule,
    MatBottomSheetModule,
    ],
   entryComponents:[CheckoutDialog, CouponDialog],
  providers: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
